import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import * as Yup from 'yup';

import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import LoginLayout from 'app/modules/login/LoginLayout';
import { Button, FilledInput, FormControl, FormHelperText, Grid, InputLabel, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useNavigate } from 'react-router-dom';
import { PHONE_REGEX } from 'app/config/constants';

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const [success, setSuccess] = useState<string>();
  const navigate = useNavigate();
  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = ({ phoneNumber }) => {
    dispatch(handlePasswordResetInit(phoneNumber));
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      setSuccess(translate(successMessage));
    }
  }, [successMessage]);

  // if (success) return <Navigate to={'/account/reset/finish'} replace />;

  return (
    <LoginLayout>
      <Grid container direction="column" spacing={5} justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="textPrimary" gutterBottom variant="h5">
                <Translate contentKey={'reset.request.title'}>Enter your phoneNumber to reset your password</Translate>
              </Typography>
              {!success && (
                <Typography fontSize={'1rem'} color="textSecondary">
                  <Translate contentKey={'reset.request.messages.info'}>Enter your phoneNumber to reset your password</Translate>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!success ? (
            <Formik
              initialValues={{
                phoneNumber: '',
              }}
              validationSchema={Yup.object().shape({
                phoneNumber: Yup.string()
                  .trim()
                  .matches(PHONE_REGEX, translate('error.form.invalidPhone'))
                  .required(translate('error.form.required')),
              })}
              onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  handleValidSubmit({ phoneNumber: values.phoneNumber });
                  if (scriptedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);
                  if (scriptedRef.current) {
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                    <InputLabel htmlFor="outlined-adornment-phoneNumber">
                      <Translate contentKey="kafaatApp.employee.phoneNumber">phoneNumber</Translate>
                    </InputLabel>
                    <FilledInput
                      id="outlined-adornment-phoneNumber"
                      type={'text'}
                      value={values.phoneNumber}
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {' '}
                        {errors.phoneNumber}{' '}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Grid container mt={4} justifyContent={'flex-end'}>
                    <Grid item>
                      <Stack direction="row" spacing={1}>
                        <Button
                          sx={{
                            padding: '20px 50px',
                          }}
                          size={'large'}
                          onClick={() => navigate('/login')}
                          variant="outlined"
                          color={'secondary'}
                        >
                          <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                        </Button>
                        <Button
                          sx={{
                            padding: '20px 70px',
                          }}
                          color="primary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          <Translate contentKey={'reset.request.form.button'}>Submit</Translate>
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : (
            <Typography>{success}</Typography>
          )}
        </Grid>
      </Grid>
    </LoginLayout>
  );
};

export default PasswordResetInit;
