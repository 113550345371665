import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBusinessCategory } from 'app/shared/model/business-category.model';
import { getEntity, updateEntity, createEntity, reset } from './business-category.reducer';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { getAllEntities as getAllEmployees } from 'app/entities/employee/employee.reducer';
import moment from 'moment/moment';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'app/config/constants';
import { AttachmentIcon } from 'app/components/icons';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapPicker from 'react-google-map-picker';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';

export const BusinessCategoryUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const isNew = id === undefined;
  const businessCategoryEntity = useAppSelector(state => state.businessCategory.entity);

  const loading = useAppSelector(state => state.businessCategory.loading);
  const updateSuccess = useAppSelector(state => state.businessCategory.updateSuccess);

  const handleClose = () => {
    setOpen(false);
    navigate('/category' + location.search);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...values,
    };

    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(entity)); // Send entity directly instead of FormData
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.businessCategory.created'), { variant: 'success' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(updateEntity(entity));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.businessCategory.updated'), { variant: 'success' });
      }
      return requestStatus;
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.businessCategory.home.createLabel">Create new category</Translate>
        ) : (
          <Translate contentKey="kafaatApp.businessCategory.home.editLabel">Edit category</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  name: undefined,
                  nameEn: undefined,
                }
              : {
                  ...businessCategoryEntity,
                  name: businessCategoryEntity.name ? businessCategoryEntity.name : undefined,
                  nameEn: businessCategoryEntity.nameEn ? businessCategoryEntity.nameEn : undefined,
                }
          }
          validationSchema={Yup.object().shape({
            name: Yup.mixed().nullable().required(translate('error.form.required')),
            nameEn: Yup.string().required(translate('error.form.required')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const requestStatus = await saveEntity(values);

              if (scriptedRef.current && requestStatus === 'fulfilled') {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.name && errors.name)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.businessCategory.name">name </Translate>
                      </FormLabel>
                      <FilledInput
                        multiline
                        rows={3}
                        type={'text'}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.name.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.nameEn && errors.nameEn)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.businessCategory.nameEn">name english </Translate>
                      </FormLabel>
                      <FilledInput
                        multiline
                        rows={3}
                        type={'text'}
                        name="nameEn"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nameEn}
                      />
                      {touched.nameEn && errors.nameEn && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.nameEn.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <br /> <br />
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default BusinessCategoryUpdate;
