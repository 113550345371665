import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import LoginLayout from 'app/modules/login/LoginLayout';
import useScriptRef from 'app/hooks/useScriptRef';

import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';

export const PasswordResetFinishPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [success, setSuccess] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const scriptedRef = useScriptRef();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleValidSubmit = async ({ email, newPassword }) => {
    const {
      meta: { requestStatus },
    } = await dispatch(handlePasswordResetFinish({ email, key, newPassword }));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('reset.finish.messages.success'), { variant: 'success' });
      navigate('/login');
    } else {
      setErrorMsg(translate('error.resetPasswordError'));
    }
  };

  const getResetForm = () => {
    return (
      <>
        <Formik
          initialValues={{
            newPassword: '',
            confirmPassword: '',
            email: '',
          }}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string().min(4).max(50).required(translate('global.messages.validate.newpassword.required')),
            email: Yup.string().min(10).required(translate('error.form.required')),
            confirmPassword: Yup.string()
              .min(4)
              .max(50)
              .required(translate('global.messages.validate.confirmpassword.required'))
              .oneOf([Yup.ref('newPassword'), null], translate('global.messages.error.dontmatch')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await handleValidSubmit({ email: values.email, newPassword: values.newPassword });
              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl fullWidth margin="normal" error={Boolean(touched.email && errors.email)}>
                <InputLabel htmlFor="outlined-adornment-password">
                  <Translate contentKey="kafaatApp.employee.phoneNumber">Phone Number </Translate>
                </InputLabel>
                <FilledInput type={'text'} value={values.email} name="email" onBlur={handleBlur} onChange={handleChange} />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text">
                    {' '}
                    {errors.email.toString()}{' '}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="normal" error={Boolean(touched.newPassword && errors.newPassword)}>
                <InputLabel htmlFor="outlined-adornment-password">
                  <Translate contentKey="global.form.newpassword.label">New Password </Translate>
                </InputLabel>
                <FilledInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.newPassword}
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: 'transparent' }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.newPassword && errors.newPassword && (
                  <FormHelperText error id="standard-weight-helper-text">
                    {' '}
                    {errors.newPassword.toString()}{' '}
                  </FormHelperText>
                )}
              </FormControl>
              <PasswordStrengthBar password={values.newPassword} />
              <FormControl fullWidth margin="normal" error={Boolean(touched.confirmPassword && errors.confirmPassword)}>
                <InputLabel htmlFor="outlined-adornment-confirmpassword">
                  <Translate contentKey="global.form.confirmpassword.label">confirm Password </Translate>
                </InputLabel>
                <FilledInput
                  id="outlined-adornment-confirmpassword"
                  type={showPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: 'transparent' }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error id="standard-weight-helper-text">
                    {' '}
                    {errors.confirmPassword.toString()}{' '}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid container mt={4} justifyContent={'flex-end'}>
                <Grid item>
                  <Stack direction="row" spacing={1}>
                    <Button
                      sx={{
                        padding: '20px 50px',
                      }}
                      size={'large'}
                      onClick={() => navigate('/login')}
                      variant="outlined"
                      color={'secondary'}
                    >
                      <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                    </Button>
                    <Button
                      sx={{
                        padding: '20px 70px',
                      }}
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <Translate contentKey="reset.finish.form.button">Submit</Translate>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              {errorMsg && <Typography color={'red'}>{errorMsg}</Typography>}
            </form>
          )}
        </Formik>
      </>
    );
  };
  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      setSuccess(translate(successMessage));
    }
  }, [successMessage]);

  return (
    <LoginLayout>
      <Grid container direction="column" spacing={5} justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="textPrimary" gutterBottom variant="h5">
                <Translate contentKey="reset.finish.title">Reset password</Translate>
              </Typography>
              {!success && (
                <Typography fontSize={'1rem'} color="textSecondary">
                  <Translate contentKey={'reset.finish.messages.info'}>Enter your email address to reset your password</Translate>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>{key ? getResetForm() : null}</div>
        </Grid>
      </Grid>
    </LoginLayout>
  );
};

export default PasswordResetFinishPage;
