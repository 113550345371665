import React, { useEffect, useState } from 'react';
import './whatsapp-button.scss';
import axios from 'axios';

interface WhatsAppButtonProps {
  phoneNumber?: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const [number, setNumber] = useState(phoneNumber);

  useEffect(() => {
    if (!phoneNumber) {
      axios
        .get('/api/config/whatsapp', { headers: { Authorization: '' } })
        .then(response => {
          setNumber(response.data.phoneNumber);
        })
        .catch(error => {
          console.error('Failed to fetch WhatsApp number:', error);
        });
    }
  }, [phoneNumber]);

  const handleClick = () => {
    if (number) {
      window.open(`https://wa.me/${number}`, '_blank');
    }
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <i className="fab fa-whatsapp"></i>
    </div>
  );
};

export default WhatsAppButton;
