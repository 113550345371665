import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BusinessCategory from './business-category';

const BusinessCategoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BusinessCategory />} />
  </ErrorBoundaryRoutes>
);

export default BusinessCategoryRoutes;
