import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '@fontsource/readex-pro';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppRoutes from 'app/routes';
import { ThemeProvider } from '@mui/material';
import { theme } from 'app/themes';
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import WhatsAppButton from 'app/shared/components/WhatsAppButton';
// Create rtl cache
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
  interface DefaultTheme extends Theme {}
}
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: 'muiltr',
});
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
export const App = () => {
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', currentLocale === 'ar-ly' ? 'rtl' : 'ltr');
  }, [currentLocale]);
  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    document.dir = currentLocale === 'ar-ly' ? 'rtl' : 'ltr';
  }, [currentLocale]);
  return (
    <BrowserRouter basename={baseHref}>
      {/*<ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />*/}
      {/*<ErrorBoundary>*/}
      <ThemeProvider theme={theme(currentLocale)}>
        <CacheProvider value={currentLocale === 'ar-ly' ? cacheRtl : cacheLtr}>
          <SnackbarProvider maxSnack={3}>
            <AppRoutes />
          </SnackbarProvider>
        </CacheProvider>
      </ThemeProvider>
      {/*</ErrorBoundary>*/}
      <WhatsAppButton phoneNumber="" />
    </BrowserRouter>
  );
};

export default App;
