import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './business.reducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FilledInput,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DeleteIcon, DownloadQRIcon, EditIcon } from 'app/components/icons';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import BusinessUpdate from 'app/entities/business/business-update';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapPicker from 'react-google-map-picker';
import OfferUpdate from 'app/entities/business/offer-update';
import OfferDeleteDialog from 'app/entities/business/offer-delete-dialog';
import { enableDisableEntity } from './offer.reducer';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import { Spinner } from 'reactstrap';

const DefaultZoom = 6;
const DefaultLocation = { lat: 24.774265, lng: 46.738586 };

export const BusinessDetail = () => {
  const dispatch = useAppDispatch();
  const [updateBusinessOpen, setUpdateBusinessOpen] = React.useState(false);
  const [updateBusinessId, setUpdateBusinessId] = React.useState(undefined);
  const [updateOfferOpen, setUpdateOfferOpen] = React.useState(false);
  const [updateOfferId, setUpdateOfferId] = React.useState(undefined);
  const [deleteOfferOpen, setDeleteOfferOpen] = React.useState(false);
  const [deleteOfferId, setDeleteOfferId] = React.useState(undefined);
  const [updateOfferObj, setUpdateOfferObj] = React.useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const { id } = useParams<'id'>();
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);
  const [zoom, setZoom] = useState(DefaultZoom);
  const updateSuccess = useAppSelector(state => state.offer.updateSuccess);

  const handleChangeLocation = (lat: number, lng: number) => {};
  const handleChangeZoom = newZoom => {
    setZoom(newZoom);
  };
  useEffect(() => {
    dispatch(getEntity(id));
  }, [updateSuccess]);

  const enableDisable = async (id, enabled) => {
    const {
      meta: { requestStatus },
    } = await dispatch(
      enableDisableEntity({
        id,
        enabled,
      })
    );
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(
        enabled ? translate('kafaatApp.business.offer.enableSuccess') : translate('kafaatApp.business.offer.disableSuccess'),
        {
          variant: 'success',
        }
      );
    }
  };
  const businessEntity = useAppSelector(state => state.business.entity);

  const offersList = businessEntity.offers;
  return (
    <>
      {businessEntity?.id?.toString() !== id ? (
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'500px'}>
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
            <Button
              color="info"
              size="large"
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => {
                setUpdateOfferId(undefined);
                setUpdateOfferOpen(true);
              }}
            >
              <Translate contentKey="kafaatApp.business.createOffer">Create Offer</Translate>
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button
              color="info"
              size="large"
              variant="text"
              startIcon={
                <IconButton sx={{ padding: 0, color: 'transparent' }}>
                  <EditIcon />
                </IconButton>
              }
              onClick={() => {
                setUpdateBusinessId(id);
                setUpdateBusinessOpen(true);
              }}
            >
              <Translate contentKey="kafaatApp.business.editBusiness">Edit Business</Translate>
            </Button>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: '#B18758',
              padding: '1rem',
              margin: '1rem',
              color: '#fff',
              height: '6rem',
            }}
          >
            <Grid item xs={6}>
              <Typography variant={'h4'}>{currentLocale === 'ar-ly' ? businessEntity.name : businessEntity.nameEn}</Typography>
            </Grid>
            <Grid item xs={2}>
              {businessEntity.logo && (
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    marginTop: '1.5rem',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    background: `url(${businessEntity.logo.filePath}) no-repeat center top`,
                    backgroundSize: '80px 80px',
                    color: 'transparent',
                    padding: '0.5rem',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  }}
                ></Box>
              )}
            </Grid>
            <Grid item xs={4} justifyContent={'end'}>
              <Grid item container rowSpacing={3} columnSpacing={2} justifyContent={'end'}>
                <Grid item sx={{ textAlign: 'center' }}>
                  <Typography>{businessEntity.offers?.length}</Typography>
                  <Typography color={'#fff'} variant="caption" sx={{ opacity: '50%' }}>
                    <Translate contentKey="kafaatApp.business.totalOffers">Total Offers</Translate>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2}>
            <br />
            <Typography color="secondary" variant="subtitle1" gutterBottom>
              <Translate contentKey="kafaatApp.business.description">Description </Translate>
            </Typography>
            {businessEntity?.description && (
              <Typography variant="subtitle1" color={'primary.dark'} gutterBottom>
                {currentLocale === 'ar-ly' ? businessEntity?.description : businessEntity?.descriptionEn}
              </Typography>
            )}
            {businessEntity?.qrCode && (
              <Grid>
                <Typography color="secondary" variant="subtitle1" gutterBottom>
                  <Translate contentKey="kafaatApp.business.businessQR">Business QR </Translate>
                </Typography>
                <img src={`data:image/png;base64,${businessEntity?.qrCode}`} />
                <br />
                <br />
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    const a = document.createElement('a');
                    a.href = 'data:image/png;base64,' + businessEntity?.qrCode;
                    a.download = 'Image.png';
                    a.click();
                  }}
                >
                  <Translate contentKey="kafaatApp.business.download">Download</Translate>
                </Button>
              </Grid>
            )}
            <br />
            <Typography color="secondary" variant="subtitle1" gutterBottom>
              <Translate contentKey="kafaatApp.business.businessLocations">Business Locations </Translate>
            </Typography>
            {businessEntity.locations?.length > 0 &&
              businessEntity.locations.map((location, index) => (
                <div key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <FormControl margin="normal" size="small" fullWidth>
                        <FormLabel>
                          <Translate contentKey="kafaatApp.business.locationName">Location Name </Translate>
                        </FormLabel>
                        <FilledInput
                          id={`business-location-name${index}`}
                          type={'text'}
                          disabled
                          value={currentLocale === 'ar-ly' ? location.name : location.nameEn}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'flex-end'}>
                      <FormControl margin="normal">
                        <Button
                          size={'large'}
                          aria-labelledby="location-business-button"
                          variant="outlined"
                          onClick={() => {
                            setDefaultLocation({
                              lat: parseFloat(location.latitude),
                              lng: parseFloat(location.longitude),
                            });
                            setLocationModalOpen(true);
                          }}
                          startIcon={<LocationOnIcon />}
                        >
                          <Translate contentKey="kafaatApp.business.locationOnMap">Location on Map</Translate>
                        </Button>
                        <Dialog open={locationModalOpen} onClose={() => setLocationModalOpen(false)}>
                          <DialogContent>
                            <MapPicker
                              defaultLocation={defaultLocation}
                              zoom={zoom}
                              style={{ height: '600px', width: '500px' }}
                              onChangeLocation={handleChangeLocation}
                              onChangeZoom={handleChangeZoom}
                              apiKey="AIzaSyDbThmL-zw_rk-mWcSGVnRgm1y-zaiwgIA"
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button size={'large'} onClick={() => setLocationModalOpen(false)} variant="outlined" color={'secondary'}>
                              <Translate contentKey="kafaatApp.business.home.close">Close</Translate>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div>
                    {location?.qrCode && (
                      <Grid>
                        <Typography variant="subtitle2" color={'primary.dark'} gutterBottom>
                          <Translate contentKey="kafaatApp.business.locationQR">locationQR </Translate>
                        </Typography>
                        <img src={`data:image/png;base64,${location?.qrCode}`} />
                        <br />
                        <br />
                        <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          startIcon={<DownloadIcon />}
                          onClick={() => {
                            const a = document.createElement('a');
                            a.href = 'data:image/png;base64,' + location?.qrCode;
                            a.download = 'Image.png';
                            a.click();
                          }}
                        >
                          <Translate contentKey="kafaatApp.business.download">Download</Translate>
                        </Button>
                      </Grid>
                    )}
                  </div>
                </div>
              ))}
            <br />
            <br />
            <Typography color="secondary" variant="subtitle1" gutterBottom>
              <Translate contentKey="kafaatApp.business.businessOffers">Business Offers </Translate>
            </Typography>
            <List sx={{ bgcolor: 'background.paper' }}>
              {offersList?.length > 0 ? (
                offersList?.map((offer: any) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>{currentLocale === 'ar-ly' ? offer.title : offer.titleEn}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Switch
                            color={'secondary'}
                            checked={offer.enabled}
                            onChange={() => {
                              enableDisable(offer.id, !offer.enabled);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                            {offer?.qr && (
                              <Tooltip title={<Translate contentKey="entity.action.downloadQR">downloadQR</Translate>}>
                                <IconButton
                                  sx={{ color: 'transparent' }}
                                  onClick={() => {
                                    const a = document.createElement('a');
                                    a.href = 'data:image/png;base64,' + offer?.qr;
                                    a.download = 'Image.png';
                                    a.click();
                                  }}
                                >
                                  <DownloadQRIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <IconButton
                                sx={{ color: 'transparent' }}
                                onClick={() => {
                                  setUpdateOfferObj(offer);
                                  setUpdateOfferId(offer.id);
                                  setUpdateOfferOpen(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <IconButton
                                sx={{ color: 'transparent' }}
                                onClick={() => {
                                  setDeleteOfferId(offer.id);
                                  setDeleteOfferOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                ))
              ) : (
                <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                  <Typography variant={'subtitle1'} color={'gray'}>
                    <Translate contentKey={'home.noData'} />
                  </Typography>
                </Box>
              )}
            </List>
          </Box>
        </>
      )}
      <BusinessUpdate open={updateBusinessOpen} setOpen={setUpdateBusinessOpen} id={updateBusinessId} />
      <OfferDeleteDialog open={deleteOfferOpen} setOpen={setDeleteOfferOpen} id={deleteOfferId} businessId={businessEntity.id} />
      <OfferUpdate
        open={updateOfferOpen}
        setOpen={setUpdateOfferOpen}
        id={updateOfferId}
        businessId={businessEntity.id}
        // type={updateOfferType}
        offerEntity={updateOfferObj}
      />
    </>
  );
};

export default BusinessDetail;
