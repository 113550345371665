import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/storage">
        <Translate contentKey="global.menu.entities.storage" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/company-type">
        <Translate contentKey="global.menu.entities.companyType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/company">
        <Translate contentKey="global.menu.entities.company" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-profile">
        <Translate contentKey="global.menu.entities.userProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role">
        <Translate contentKey="global.menu.entities.role" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/permission">
        <Translate contentKey="global.menu.entities.permission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/base-entity">
        <Translate contentKey="global.menu.entities.baseEntity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/company-admin">
        <Translate contentKey="global.menu.entities.companyAdmin" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/category">
        <Translate contentKey="global.menu.entities.businessCategory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/business">
        <Translate contentKey="global.menu.entities.business" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contact">
        <Translate contentKey="global.menu.entities.contact" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/offer">
        <Translate contentKey="global.menu.entities.offer" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/bookmark">
        <Translate contentKey="global.menu.entities.bookmark" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/gift">
        <Translate contentKey="global.menu.entities.gift" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/offer-log">
        <Translate contentKey="global.menu.entities.offerLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification">
        <Translate contentKey="global.menu.entities.notification" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
