import './home.scss';

import React, { useEffect } from 'react';

import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { translate, Translate } from 'react-jhipster';
import { BuyIcon, ScanIcon, TicketIcon, UserIcon, WorkIcon } from 'app/components/icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, getRedeemers } from 'app/modules/home/dashboard.reducer';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

export const Home = () => {
  const dispatch = useAppDispatch();
  const dashboardEntity = useAppSelector(state => state.dashboard.entity);
  const redeemersEntity = useAppSelector(state => state.dashboard.redeemers);
  const [showRange, setShowRange] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = account?.authorities?.includes('ROLE_ADMIN');

  useEffect(() => {
    dispatch(getEntity());
    const today = moment();
    const from_date = today.clone().startOf('week');
    const to_date = today.clone().endOf('week');
    dispatch(getRedeemers({ from: from_date.format('YYYY-MM-DD'), to: to_date.format('YYYY-MM-DD') }));
  }, []);

  const data01 = [
    { name: translate('home.totalCodeRedeems'), value: dashboardEntity?.totalCodeRedeems },
    { name: translate('home.totalQRCodeRedeems'), value: dashboardEntity?.totalQRCodeRedeems },
  ];

  useEffect(() => {
    if (fromDate && toDate)
      dispatch(
        getRedeemers({
          from: fromDate.format('YYYY-MM-DD'),
          to: toDate.format('YYYY-MM-DD'),
        })
      );
  }, [fromDate, toDate]);
  return (
    <Box m={2}>
      <Grid container spacing={3} justifyContent={'center'} width={'100%'} display={'flex'}>
        {isAdmin && (
          <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
            <Paper
              component={Stack}
              sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
              direction="column"
              justifyContent="center"
            >
              <Box>
                <UserIcon />
              </Box>
              <Typography variant={'h5'} color={'secondary'}>
                {dashboardEntity?.numberOfEntities}
              </Typography>
              <Typography variant={'subtitle1'} color={'primary.dark'}>
                <Translate contentKey={'home.numberOfEntities'} />
              </Typography>
            </Paper>
          </Grid>
        )}
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <UserIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.numOfRegisteredEmployees}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.numOfRegisteredEmployees'} />
            </Typography>
          </Paper>
        </Grid>
        {isAdmin && (
          <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
            <Paper
              component={Stack}
              sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
              direction="column"
              justifyContent="center"
            >
              <Box>
                <BuyIcon />
              </Box>
              <Typography variant={'h5'} color={'secondary'}>
                {dashboardEntity?.numOfBusiness}
              </Typography>
              <Typography variant={'subtitle1'} color={'primary.dark'}>
                <Translate contentKey={'home.numOfBusiness'} />
              </Typography>
            </Paper>
          </Grid>
        )}
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <WorkIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.numOfOffers}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.numOfOffers'} />
            </Typography>
          </Paper>
        </Grid>
        {isAdmin && (
          <>
            <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
              <Paper
                component={Stack}
                sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                direction="column"
                justifyContent="center"
              >
                <Box>
                  <WorkIcon />
                </Box>
                <Typography variant={'h5'} color={'secondary'}>
                  {dashboardEntity?.numOfActiveOffers}
                </Typography>
                <Typography variant={'subtitle1'} color={'primary.dark'}>
                  <Translate contentKey={'home.numOfActiveOffers'} />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
              <Paper
                component={Stack}
                sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                direction="column"
                justifyContent="center"
              >
                <Box>
                  <BuyIcon />
                </Box>
                <Typography variant={'h5'} color={'secondary'}>
                  {dashboardEntity?.numOfActiveBusinesses}
                </Typography>
                <Typography variant={'subtitle1'} color={'primary.dark'}>
                  <Translate contentKey={'home.numOfActiveBusinesses'} />
                </Typography>
              </Paper>
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <ScanIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.totalQRCodeRedeems}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.totalQRCodeRedeems'} />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <ScanIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.totalCodeRedeems}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.totalCodeRedeems'} />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <ScanIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.totalRedeems}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.totalRedeems'} />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Paper
            component={Stack}
            sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
            direction="column"
            justifyContent="center"
          >
            <Box>
              <TicketIcon />
            </Box>
            <Typography variant={'h5'} color={'secondary'}>
              {dashboardEntity?.numOfGifts}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary.dark'}>
              <Translate contentKey={'home.numOfGifts'} />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper component={Stack} style={{ width: '100%', height: '400px' }}>
            <Box m={2}>
              <Typography variant={'subtitle1'} color={'primary.dark'}>
                <Translate contentKey={'home.offersExpiredInOneMonth'} />
              </Typography>
            </Box>
            {dashboardEntity?.offersExpiredInOneMonth.length > 0 ? (
              <Table aria-label="custom pagination table">
                <TableHead style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '15px 20px' }} width={'50%'}>
                      <Translate contentKey="home.offer">offer</Translate>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '15px 20px' }} width={'50%'}>
                      <Translate contentKey="home.businessName">businessName</Translate>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: '280px', overflowY: 'auto', display: 'block' }}>
                  {dashboardEntity?.offersExpiredInOneMonth.map(offer => (
                    <TableRow key={offer.id} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{offer.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color={'primary.dark'}>{offer.businessName}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper component={Stack} style={{ height: '400px', width: '100%' }} direction="column">
            <Grid container m={3} width={'90%'}>
              <Grid item xs={6}>
                <Typography variant={'subtitle1'} color={'primary.dark'}>
                  <Translate contentKey={'home.topRedeemers'} />
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                <FormControl
                  size={'small'}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                >
                  <Select
                    defaultValue={1}
                    onChange={(e: any) => {
                      const today = moment();
                      switch (e.target.value) {
                        case 1: {
                          setShowRange(false);
                          const from_date = today.clone().startOf('week');
                          const to_date = today.clone().endOf('week');
                          dispatch(
                            getRedeemers({
                              from: from_date.format('YYYY-MM-DD'),
                              to: to_date.format('YYYY-MM-DD'),
                            })
                          );
                          return 1;
                        }
                        case 2: {
                          setShowRange(false);
                          const from_date = today.clone().startOf('month');
                          const to_date = today.clone().endOf('month');
                          dispatch(
                            getRedeemers({
                              from: from_date.format('YYYY-MM-DD'),
                              to: to_date.format('YYYY-MM-DD'),
                            })
                          );
                          return 2;
                        }
                        case 3: {
                          const from_date = today.clone().startOf('year');
                          const to_date = today.clone().endOf('year');
                          dispatch(
                            getRedeemers({
                              from: from_date.format('YYYY-MM-DD'),
                              to: to_date.format('YYYY-MM-DD'),
                            })
                          );
                          setShowRange(false);
                          return 3;
                        }
                        default: {
                          setShowRange(true);
                          return 4;
                        }
                      }
                    }}
                  >
                    <MenuItem key={1} value={1}>
                      <Translate contentKey={'home.thisWeek'} />
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      <Translate contentKey={'home.thisMonth'} />
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      <Translate contentKey={'home.thisYear'} />
                    </MenuItem>
                    <MenuItem key={4} value={4}>
                      <Translate contentKey={'home.selectRange'} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {showRange && (
                <Grid container xs={12} columnSpacing={3} justifyItems={'center'}>
                  <Grid item xs={6}>
                    <FormControl
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormLabel
                        sx={{
                          lineHeight: '2.5',
                          px: '10px',
                        }}
                      >
                        <Translate contentKey="home.fromDate">fromDate </Translate>
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={fromDate}
                          onChange={event => {
                            setFromDate(event);
                          }}
                          renderInput={params => <TextField size={'small'} {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormLabel
                        sx={{
                          lineHeight: '2.5',
                          px: '10px',
                        }}
                      >
                        <Translate contentKey="home.toDate">toDate </Translate>
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={toDate}
                          onChange={event => {
                            setToDate(event);
                          }}
                          renderInput={params => <TextField size={'small'} {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {redeemersEntity?.find(a => a.value !== 0) ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={redeemersEntity}>
                  <XAxis dataKey="firstName" />
                  <YAxis />
                  <Bar dataKey="totalRedeeming" fill="#748E7F" name={translate('home.report.total')} maxBarSize={50} />
                  <Tooltip
                    content={({ active, payload, label }: any) => {
                      if (active && payload && payload.length) {
                        const data = payload[0].payload;
                        return (
                          <div
                            dir={currentLocale === 'ar-ly' ? 'rtl' : 'ltr'}
                            style={{
                              background: 'rgba(255, 255, 255, 0.9)',
                              border: 'solid 1px rgba(0, 0, 0, 0.1)',
                              padding: '10px',
                              fontSize: '12px',
                              color: '#37483F',
                            }}
                          >
                            <p>
                              <span style={{ color: '#748E7F' }}>
                                <Translate contentKey={'home.report.company'} />
                              </span>
                              {currentLocale === 'ar-ly' ? data.companyName : data.companyNameEn}
                            </p>
                            <p>
                              <span style={{ color: '#748E7F' }}>
                                <Translate contentKey={'home.report.email'} />
                              </span>
                              {data.email}
                            </p>
                            <p>
                              <span style={{ color: '#748E7F' }}>
                                <Translate contentKey={'home.report.totalTooltip'} />
                              </span>
                              {data.totalRedeeming}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    }}
                    cursor={{ fill: 'transparent' }}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
