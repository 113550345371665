import './reports.scss';

import React, { useEffect, useState } from 'react';

import { Box, Button, FormControl, FormLabel, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { translate, Translate } from 'react-jhipster';
import { ChartIcon } from 'app/components/icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DownloadIcon from '@mui/icons-material/Download';

import { getEntity, getPDF, reset } from 'app/modules/reports/reports.reducer';
import { ResponsiveContainer, Tooltip, XAxis, YAxis, Bar, BarChart, Legend, PieChart, Pie, Cell } from 'recharts';
import { COLORS } from 'app/config/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Reports = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.reports.loading);
  const reportsEntity = useAppSelector(state => state.reports.entity);
  const reportsPdf = useAppSelector(state => state.reports.pdf);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [consumedData, setConsumedData] = useState([]);
  const [redeemersData, setRedeemersData] = useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  useEffect(() => {
    dispatch(getEntity({}));
  }, []);
  const mock01 = [
    {
      businessName: 'aaa',
      total: 50,
      type: 'OFFER',
    },
    {
      businessName: 'bbb',
      total: 30,
    },
    {
      businessName: 'ccc',
      total: 10,
      type: 'OFFER',
    },
  ];

  const mock02 = [
    {
      username: 'dddddd bbbbbb',
      totalRedeeming: 50,
    },
    {
      username: 'xxxxxxxc sddvbgbrfh',
      totalRedeeming: 100,
    },
    {
      username: 'xxxxxxxc sddvbgbrfh',
      totalRedeeming: 30,
    },
    {
      username: 'dddddd bbbbbb',
      totalRedeeming: 4,
    },
  ];

  useEffect(() => {
    if (reportsPdf) {
      const url = window.URL.createObjectURL(new Blob([reportsPdf]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report.xlsx`);
      document.body.appendChild(link);
      link.click();
      dispatch(reset());
      if (fromDate && toDate) dispatch(getEntity({ from: fromDate.format('YYYY-MM-DD'), to: toDate.format('YYYY-MM-DD') }));
      else dispatch(getEntity({}));
    }
  }, [reportsPdf]);

  useEffect(() => {
    const temp = [];
    reportsEntity?.consumedData?.forEach(item => {
      const found = temp.find(i => i.businessName === item.businessName);
      if (found) {
        found.totalOffers = item.total;
      } else
        temp.push({
          businessName: item.businessName,
          totalOffers: item.total,
        });
    });
    setConsumedData(temp);
    const temp2 = [];
    reportsEntity?.redeemersData?.forEach(item => {
      const found = temp2.find(i => i.username === item.username);
      if (found) {
        found.totalQR = item.type === 'QR' ? item.total : 0;
        found.totalCode = item.type === 'Code' ? item.total : 0;
      } else
        temp2.push({
          username: item.username,
          email: item.email,
          companyName: item.companyName,
          companyNameEn: item.companyNameEn,
          totalQR: item.type === 'QR' ? item.total : 0,
          totalCode: item.type === 'Code' ? item.total : 0,
        });
    });
    setRedeemersData(temp2);
  }, [reportsEntity]);
  useEffect(() => {
    if (fromDate && toDate) dispatch(getEntity({ from: fromDate.format('YYYY-MM-DD'), to: toDate.format('YYYY-MM-DD') }));
  }, [fromDate, toDate]);
  return (
    <Box m={2}>
      <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
        <Stack direction={'row'}>
          <Grid container columnSpacing={3} justifyContent={'flex-end'}>
            <Grid item xs={2} pt={1}>
              <Typography variant={'subtitle1'} color={'gray'}>
                <Translate contentKey={'home.report.filter'}>filter</Translate>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormLabel
                  sx={{
                    lineHeight: '2.5',
                    px: '10px',
                  }}
                >
                  <Translate contentKey="home.fromDate">fromDate </Translate>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={fromDate}
                    onChange={event => {
                      setFromDate(event);
                    }}
                    renderInput={params => <TextField size={'small'} {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormLabel
                  sx={{
                    lineHeight: '2.5',
                    px: '10px',
                  }}
                >
                  <Translate contentKey="home.toDate">toDate </Translate>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={toDate}
                    onChange={event => {
                      setToDate(event);
                    }}
                    renderInput={params => <TextField size={'small'} {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={3} justifyContent={'flex-end'} display={'flex'} width={'100%'}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                startIcon={<DownloadIcon />}
                disabled={loading}
                onClick={() => {
                  if (fromDate && toDate)
                    dispatch(
                      getPDF({
                        language: currentLocale === 'ar-ly' ? 'ar' : 'en',
                        from: fromDate.format('YYYY-MM-DD'),
                        to: toDate.format('YYYY-MM-DD'),
                      })
                    );
                  else dispatch(getPDF({ language: currentLocale === 'ar-ly' ? 'ar' : 'en' }));
                }}
              >
                {!loading && <Translate contentKey="home.report.download">Download Report</Translate>}
                {loading && <Translate contentKey="home.report.prepareReport">Prepare Report</Translate>}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.registeredUsers'} />
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              <Translate contentKey={'home.report.statistics'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Paper component={Stack} style={{ textAlign: 'center', height: '100%' }} direction="column" justifyContent="center">
            {reportsEntity?.registeredUsersStatistics?.length > 0 ? (
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant={'h4'} color={'secondary'}>
                    {reportsEntity?.registeredUsersStatistics[0]?.numOfRegisteredEmployeesPerDay}
                  </Typography>
                  <Typography variant={'h5'} color={'primary.dark'} sx={{ m: 2 }}>
                    <Translate contentKey={'home.report.numOfRegisteredEmployeesPerDay'} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'h4'} color={'secondary'}>
                    {reportsEntity?.registeredUsersStatistics[0]?.numOfRegisteredEmployeesPerWeek}
                  </Typography>
                  <Typography variant={'h5'} color={'primary.dark'} sx={{ m: 2 }}>
                    <Translate contentKey={'home.report.numOfRegisteredEmployeesPerWeek'} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'h4'} color={'secondary'}>
                    {reportsEntity?.registeredUsersStatistics[0]?.numOfRegisteredEmployeesPerMonth}
                  </Typography>
                  <Typography variant={'h5'} color={'primary.dark'} sx={{ m: 2 }}>
                    <Translate contentKey={'home.report.numOfRegisteredEmployeesPerMonth'} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'h4'} color={'secondary'}>
                    {reportsEntity?.registeredUsersStatistics[0]?.numOfRegisteredEmployeesPerYear}
                  </Typography>
                  <Typography variant={'h5'} color={'primary.dark'} sx={{ m: 2 }}>
                    <Translate contentKey={'home.report.numOfRegisteredEmployeesPerYear'} />
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.consumedData'} />
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              <Translate contentKey={'home.report.perEachBusiness'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Paper style={{ height: '100%' }}>
            {consumedData?.length > 0 ? (
              <Box justifyContent={'center'} display={'flex'} alignItems={'center'} width={'100%'} height={'100%'}>
                <ResponsiveContainer width={'80%'} height={'80%'}>
                  <BarChart data={consumedData}>
                    <XAxis dataKey="businessName" />
                    <YAxis />
                    <Bar name={translate('home.report.totalOffers')} dataKey="totalOffers" fill="#748E7F" maxBarSize={50} />
                    <Legend />
                    <Tooltip cursor={{ fill: 'transparent' }} contentStyle={{ fontSize: '12px', background: 'rgba(255, 255, 255, 0.9)' }} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            ) : (
              <Box
                justifyContent={'center'}
                flexDirection={'column'}
                textAlign={'center'}
                display={'flex'}
                padding={'50px'}
                height={'100%'}
              >
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.topUsers'} />
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              <Translate contentKey={'home.report.byRedeems'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Paper style={{ height: '100%' }}>
            {reportsEntity?.topUsers?.length > 0 ? (
              <Box justifyContent={'center'} display={'flex'} alignItems={'center'} width={'100%'} height={'100%'}>
                <ResponsiveContainer width={'80%'} height={'80%'}>
                  <BarChart layout={'vertical'} data={reportsEntity?.topUsers} style={{ direction: 'ltr' }}>
                    <XAxis type="number" hide reversed />
                    <YAxis type="category" dataKey="username" orientation="right" />
                    <Bar dataKey="totalRedeeming" fill="#B18758" name={translate('home.report.total')} maxBarSize={50} />
                    <Tooltip
                      content={({ active, payload, label }: any) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div
                              dir={currentLocale === 'ar-ly' ? 'rtl' : 'ltr'}
                              style={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                border: 'solid 1px rgba(0, 0, 0, 0.1)',
                                padding: '10px',
                                fontSize: '12px',
                                color: '#37483F',
                              }}
                            >
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.company'} />
                                </span>
                                {currentLocale === 'ar-ly' ? data.companyName : data.companyNameEn}
                              </p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.email'} />
                                </span>
                                {data.email}
                              </p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.totalTooltip'} />
                                </span>
                                {data.totalRedeeming}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                      cursor={{ fill: 'transparent' }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            ) : (
              <Box
                justifyContent={'center'}
                flexDirection={'column'}
                textAlign={'center'}
                display={'flex'}
                padding={'50px'}
                height={'100%'}
              >
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.redeemersData'} />
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              <Translate contentKey={'home.report.overQRandCode'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Paper style={{ height: '100%' }}>
            {redeemersData?.length > 0 ? (
              <Box justifyContent={'center'} display={'flex'} alignItems={'center'} width={'100%'} height={'100%'}>
                <ResponsiveContainer width={'80%'} height={'80%'}>
                  <BarChart data={redeemersData}>
                    <XAxis dataKey="username" />
                    <YAxis />
                    <Bar name={translate('home.report.totalQR')} dataKey="totalQR" fill="#748E7F" maxBarSize={50} />
                    <Bar name={translate('home.report.totalCode')} dataKey="totalCode" fill="#B18758" maxBarSize={50} />
                    <Legend />
                    <Tooltip
                      cursor={{ fill: 'transparent' }}
                      content={({ active, payload, label }: any) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div
                              dir={currentLocale === 'ar-ly' ? 'rtl' : 'ltr'}
                              style={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                border: 'solid 1px rgba(0, 0, 0, 0.1)',
                                padding: '10px',
                                fontSize: '12px',
                                color: '#37483F',
                              }}
                            >
                              <p>{data.username}</p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.company'} />
                                </span>
                                {currentLocale === 'ar-ly' ? data.companyName : data.companyNameEn}
                              </p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.email'} />
                                </span>
                                {data.email}
                              </p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.totalQR'} />
                                </span>
                                {data.totalQR}
                              </p>
                              <p>
                                <span style={{ color: '#748E7F' }}>
                                  <Translate contentKey={'home.report.totalCode'} />
                                </span>
                                {data.totalCode}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            ) : (
              <Box
                justifyContent={'center'}
                flexDirection={'column'}
                textAlign={'center'}
                display={'flex'}
                padding={'50px'}
                height={'100%'}
              >
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.mostRedeeming'} />
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              <Translate contentKey={'home.report.businessesAndOffers'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} height={'400px'}>
          <Paper component={Stack} style={{ textAlign: 'center', height: '100%' }} direction="column" justifyContent="center">
            <Grid container>
              <Grid item xs={6} height={'300px'}>
                <Typography variant={'subtitle1'} color={'primary.dark'}>
                  <Translate contentKey={'home.report.mostRedeemingBusinesses'} />
                </Typography>
                {reportsEntity?.mostRedeemingBusinesses?.find(a => a.total !== 0) ? (
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={reportsEntity?.mostRedeemingBusinesses}
                        dataKey="total"
                        nameKey="businessName"
                        cx="50%"
                        cy="50%"
                        outerRadius={60}
                        fill="#B18758"
                        pointerEvents={null}
                      >
                        {reportsEntity?.mostRedeemingBusinesses?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip
                        cursor={{ fill: 'transparent' }}
                        contentStyle={{ fontSize: '12px', background: 'rgba(255, 255, 255, 0.9)' }}
                      />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                    <Typography variant={'subtitle1'} color={'gray'}>
                      <Translate contentKey={'home.noData'} />
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={6} height={'300px'}>
                <Typography variant={'subtitle1'} color={'primary.dark'}>
                  <Translate contentKey={'home.report.mostRedeemingOffers'} />
                </Typography>
                {reportsEntity?.mostRedeemingOffers?.find(a => a.total !== 0) ? (
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={reportsEntity?.mostRedeemingOffers}
                        nameKey="businessName"
                        dataKey="total"
                        cx="50%"
                        cy="50%"
                        outerRadius={60}
                        pointerEvents={null}
                      >
                        {reportsEntity?.mostRedeemingOffers?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} name={entry.businessName} />
                        ))}
                      </Pie>
                      <Tooltip
                        cursor={{ fill: 'transparent' }}
                        contentStyle={{ fontSize: '12px', background: 'rgba(255, 255, 255, 0.9)' }}
                      />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                    <Typography variant={'subtitle1'} color={'gray'}>
                      <Translate contentKey={'home.noData'} />
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3 }} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
        <Grid item xs={4} height={'400px'} alignItems={'stretch'} display={'flex'}>
          <Box component={Stack} direction="column" justifyContent="center">
            <Box>
              <ChartIcon />
            </Box>
            <Typography variant={'h5'}>
              <Translate contentKey={'home.report.mostVisitedBusinesses'} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Paper style={{ textAlign: 'center', height: '400px' }}>
            {reportsEntity?.mostVisitedBusinesses?.length > 0 ? (
              <Box justifyContent={'center'} display={'flex'} alignItems={'center'} width={'100%'} height={'100%'}>
                <ResponsiveContainer width={'80%'} height={'80%'}>
                  <BarChart data={reportsEntity?.mostVisitedBusinesses}>
                    <XAxis dataKey="businessName" />
                    <YAxis />
                    <Bar dataKey="total" name={translate('home.report.total')} fill="#748E7F" maxBarSize={50} />
                    <Tooltip cursor={{ fill: 'transparent' }} contentStyle={{ fontSize: '12px', background: 'rgba(255, 255, 255, 0.9)' }} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            ) : (
              <Box
                justifyContent={'center'}
                flexDirection={'column'}
                textAlign={'center'}
                display={'flex'}
                padding={'50px'}
                height={'100%'}
              >
                <Typography variant={'subtitle1'} color={'gray'}>
                  <Translate contentKey={'home.noData'} />
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
